// extracted by mini-css-extract-plugin
export var main = "index-module--main--3hl1I";
export var hero = "index-module--hero--2bWc9";
export var textOverlay = "index-module--textOverlay--3RYtO";
export var overlayStyle = "index-module--overlayStyle--3iS37";
export var header = "index-module--header--3qMCB";
export var h3Style = "index-module--h3Style--2gb4J";
export var subtitleStyle = "index-module--subtitleStyle--1LQyc";
export var whatwedo = "index-module--whatwedo--1VmBw";
export var blog_section_header = "index-module--blog_section_header--12Qis";
export var what_we_do_container = "index-module--what_we_do_container--uGeyb";
export var skill_item = "index-module--skill_item--r12rm";
export var skill_text_wrap = "index-module--skill_text_wrap--1z_Bj";
export var skill_img_wrap = "index-module--skill_img_wrap--L4jRK";
export var gan = "index-module--gan--12eC_";
export var caption = "index-module--caption--22U_O";
export var contact_us = "index-module--contact_us--3ZCi3";
export var contact = "index-module--contact--1CxHy";
export var blog_section = "index-module--blog_section--2N7Ar";
export var blog_item_link = "index-module--blog_item_link--1bT7q";
export var author = "index-module--author--XKvj9";
export var subtitle = "index-module--subtitle--J8TDx";