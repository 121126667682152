import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { Link } from "gatsby";
import { map } from "lodash";
import * as styles from "./index.module.scss";

function BlogHighlights() {
  const data = useStaticQuery(graphql`
    query {
      allMediumPost(sort: { fields: [createdAt], order: DESC }) {
        edges {
          node {
            id
            title
            uniqueSlug
            virtuals {
              subtitle
              previewImage {
                imageId
              }
            }
            author {
              name
            }
          }
        }
      }
    }
  `);

  //
  //  1*84nkxjgs4yVfPYz27Wx0YQ.png

  console.log("data", data);
  const blog_items = map(data.allMediumPost.edges, (edge) => {
    console.log("edge", edge);
    const node = edge.node;
    return (
      <a
        href={`https://medium.com/northwest-nfts/${edge.node.uniqueSlug}`}
        className={styles.blog_item_link}
        target="_blank"
        key={node.id}
      >
        <div id={node.id}>
          <img
            src={`https://cdn-images-1.medium.com/fit/t/1600/480/${node.virtuals.previewImage.imageId}`}
          ></img>
          <h2>
            {node.title}
            <span className={styles.author}> by {node.author.name}</span>
          </h2>
          <p className={styles.subtitle}>{node.virtuals.subtitle}</p>
        </div>
      </a>
    );
  });

  return <div className={styles.blog_section}>{blog_items}</div>;
}

export const BlogComponent = () => {
  return (
    <>
      <a
        href="https://medium.com/northwest-nfts"
        className={styles.blog_section_header}
        target="_blank"
      >
        <h1>Recent blog posts from Northwest NFTs</h1>
      </a>
      <BlogHighlights></BlogHighlights>
    </>
  );
};

const BlogPage = () => {
  return (
    <main className={styles.main}>
      <BlogComponent />
    </main>
  );
};

export default BlogPage;
